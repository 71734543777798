import React from 'react';
import { CatalogGroupFragment, CatalogQuery } from 'graphql-types';
import {
  GroupWrapper,
  GroupTitle,
  TemplatePreviews,
} from './CatalogGroupStyled';
import TemplatePreview from './TemplatePreview/TemplatePreview';

interface ICatalogGroupProps {
  categoryFiltered?: CatalogQuery['newTemplate']['nodes'];
  group?: CatalogGroupFragment;
  selectedCategoryId: string | undefined;
}

const CatalogGroup: React.FC<ICatalogGroupProps> = ({
  group,
  selectedCategoryId,
  categoryFiltered,
}) => {
  let templates = group?.templates ?? [];

  if (selectedCategoryId) {
    templates = categoryFiltered;
  }

  if (templates.length === 0) return null;

  return (
    <GroupWrapper>
      {!selectedCategoryId && (
        <GroupTitle>{group?.title?.localized ?? ''}</GroupTitle>
      )}
      <TemplatePreviews>
        {templates.map((template) => (
          <TemplatePreview
            selectedCategoryId={selectedCategoryId}
            key={template._id}
            template={template}
          />
        ))}
      </TemplatePreviews>
    </GroupWrapper>
  );
};

export default CatalogGroup;
