import React from 'react';
import { CatalogQuery } from 'graphql-types';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@assets/icons/styled/StyledIcons';
import convertImageFormat from '@helpers/convertImageFormat';
import {
  CarouselWrapper,
  IconWrapper,
  CarouselActiveImage,
  StyledShadow,
  Navbar,
  NavbarText,
} from './CatalogCarouselStyled';
import useNavigation from './__utils__/useNavigation';

interface ICatalogCarouselProps {
  highlightedTemplates: CatalogQuery['newTemplate']['nodes'] | [];
}

const CatalogCarousel: React.FC<ICatalogCarouselProps> = ({
  highlightedTemplates,
}) => {
  const { activeIndex, navigateForward, navigateBackward } =
    useNavigation(highlightedTemplates);

  if (highlightedTemplates.length === 0) {
    return null;
  }

  const activeTemplate = highlightedTemplates[activeIndex];
  const templateSlug = activeTemplate.slug.localized;
  const templateTitle = activeTemplate.title?.localized ?? '';
  const catalogSlug = activeTemplate.catalog.slug.localized;
  const backgroundImageUrl = convertImageFormat(
    activeTemplate.coverImage?.asset?.url
  );

  return (
    <CarouselWrapper
      onClick={() => {
        window.location.href = `/${catalogSlug}/${templateSlug}`;
      }}
    >
      <StyledShadow
        src={`${process.env.GATSBY_S3_ASSETS_URL}/hero-shadow.png`}
      />
      <Navbar
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconWrapper
          onClick={(e) => {
            navigateBackward();
            e.stopPropagation();
          }}
        >
          <ArrowLeftIcon width={18} height={18} />
        </IconWrapper>
        <NavbarText>{templateTitle}</NavbarText>
        <IconWrapper
          alignRight
          onClick={(e) => {
            navigateForward();
            e.stopPropagation();
          }}
        >
          <ArrowRightIcon width={18} height={18} />
        </IconWrapper>
      </Navbar>

      <CarouselActiveImage backgroundImageUrl={backgroundImageUrl ?? ''} />
    </CarouselWrapper>
  );
};

export default CatalogCarousel;
