export default (imageUrl: string) => {
  if (!imageUrl) return null;

  const searchParams = new URLSearchParams();
  searchParams.set('auto', 'format');
  searchParams.set('fit', 'max');
  searchParams.set('dpr', '2');

  if (imageUrl.includes('?')) {
    return `${imageUrl}&${searchParams.toString()}`;
  }

  return `${imageUrl}?${searchParams.toString()}`;
};
