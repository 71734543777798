import styled from 'styled-components';
import {
  ANNOUNCEMENT_SIZE,
  HEADER_SIZE,
  MOBILE_ANNOUNCEMENT_SIZE,
  MOBILE_HEADER_SIZE,
} from '@components/Layout/Header/constants';
import { CATALOG_COVER_INFO_WIDTH } from './constants';

export const CatalogWrapper = styled.div<{
  announcementBarVisible?: boolean;
}>`
  width: 100%;
  &:first-of-type {
    padding-top: ${({ announcementBarVisible }) =>
      announcementBarVisible ? ANNOUNCEMENT_SIZE + HEADER_SIZE : HEADER_SIZE}px;
    ${({ theme, announcementBarVisible }) => theme.BreakpointTablet`    
      padding-top: ${
        announcementBarVisible
          ? MOBILE_ANNOUNCEMENT_SIZE + MOBILE_HEADER_SIZE
          : MOBILE_HEADER_SIZE
      }px;  
   `};
  }
`;

export const CatalogTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.Walsheim.black};
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 70px;
  color: #000;
`;

export const CatalogDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.Walsheim.light};
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 27px;
  color: #000;
`;

export const CatalogCover = styled.div<{ backgroundImageUrl?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl &&
    `
      background-image: url('${backgroundImageUrl}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
  `}
`;

export const CoverContent = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px;
  width: 100%;
  ${({ theme }) => theme.BreakpointTablet`
          flex-wrap: wrap;
          justify-content: center;
   `};
`;

export const CatalogInfo = styled.div`
  width: ${CATALOG_COVER_INFO_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 480px;
`;

export const Category = styled.div<{ active?: boolean }>`
  padding: 6px 10px;
  border-radius: 8px;
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.colors.purple.mulledWine};
    color: #fff !important;
  `}

  :not(:first-child) {
    margin-left: 10px;
  }
`;

export const Categories = styled.div`
  background: ${({ theme }) => theme.colors.gray.athensGrayMedium};
  padding: 14px calc((100% - 950px) / 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${Category} {
    font-family: ${({ theme }) => theme.fonts.Walsheim.black};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.purple.mulledWine};
    line-height: 20px;
  }

  ${({ theme }) => theme.BreakpointMobile`
    height: 42px;
    padding: 8px 17px;
    flex-wrap: unset;
    width: fit-content;
    min-width: 100%;
    
    ${Category} {
      line-height: 12px;
      white-space: nowrap;
    }
  `};
`;

export const CategoriesWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const GroupsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.athensGrayLight};
  width: 100%;
  padding: 50px 0;
  ${({ theme }) => theme.BreakpointMobile`
      padding: 50px 30px;
  `};
`;
