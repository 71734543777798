import { useState } from 'react';
import { CatalogQuery } from 'graphql-types';

const useNavigation = (
  highlightedTemplates: CatalogQuery['newTemplate']['nodes'] | []
) => {
  const lastIndex = highlightedTemplates.length - 1;
  const [activeIndex, setActiveIndex] = useState(0);

  const navigateForward = () => {
    if (lastIndex === 0) return;
    if (activeIndex + 1 <= lastIndex) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  };

  const navigateBackward = () => {
    if (lastIndex === 0) return;
    if (activeIndex - 1 >= 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(lastIndex);
    }
  };

  return { activeIndex, navigateForward, navigateBackward };
};

export default useNavigation;
