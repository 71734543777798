import styled from 'styled-components';

export const GroupWrapper = styled.div`
  padding: 0 120px;
  margin: 0 auto;
  max-width: 1440px;
  ${({ theme }) => theme.BreakpointMobile`
      padding: 0;
  `};

  :not(:first-child) {
    margin-top: 40px;
  }
`;

export const GroupTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.Walsheim.black};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
`;

export const TemplatePreviews = styled.div`
  max-width: 1206px;
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.BreakpointMobile`
      margin-left: 0;
  `};
`;
