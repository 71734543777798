import styled from 'styled-components';

export const TemplateInfo = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 55px;
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

export const TemplateTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.Walsheim.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
`;

export const HoverLayout = styled.div`
  position: absolute;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 175px;
  width: 100%;
  background-color: #5f6cffb2;
`;

export const PreviewImage = styled.div<{
  backgroundImageUrl?: string;
  backgroundColor?: string;
}>`
  width: 100%;
  height: 175px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
  `}
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl &&
    `
      background-image: url('${backgroundImageUrl}');
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 130px;
      background-position: center;
  `}
  ${({ theme }) => theme.BreakpointMobile`
      width: 270px;
      height: 189px;
  `};
`;

export const PreviewWrapper = styled.div`
  position: relative;
  width: 360px;
  height: 231px;
  box-shadow: 0 6px 8px -6px rgba(24, 39, 75, 0.12),
    0 8px 16px -6px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  margin: 40px 20px 0;
  cursor: pointer;

  ${HoverLayout} {
    display: none;
  }

  &:hover {
    ${HoverLayout} {
      display: block;
    }
  }

  ${({ theme }) => theme.BreakpointMobile`
      width: 272px;
      height: 265px;
  `};
`;

export const IconWrapper = styled.div<{
  backgroundColor?: string;
}>`
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;

  .template-preview-icon {
    margin: 0;
    width: 14px;
    height: 14px;
  }
`;

export const PreviewButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 5px;

  svg {
    path {
      fill: #fff;
    }
  }
`;

export const ButtonText = styled.div`
  margin-left: auto;
  font-family: ${({ theme }) => theme.fonts.Walsheim.black};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
`;
