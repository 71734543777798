import React, { useEffect } from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import Catalog from '@components/Catalog/Catalog';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { ICatalogPageContextProps } from '@contexts/interfaces';
import { HeaderFragmentWithVariant } from '@components/Layout/NavigationBar/interfaces';
import { CatalogQuery } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';
import { analyticsSnippet } from './analytics/analytics';
import trackPage from './analytics/trackPage';

const CatalogOverview = ({
  data,
  pageContext,
}: PageProps<CatalogQuery, ICatalogPageContextProps>) => {
  const config = data?.catalogConfig;
  const title = config?.title?.localized || '';
  const description = config?.description?.localized || '';
  const coverImage = config?.coverImage ?? null;
  const categories = data?.catalogCategory.nodes ?? [];
  const groups = data?.catalogGroup.nodes ?? [];
  const { selectedCategoryId } = pageContext;
  const allCategoryName = data?.catalogConfig?.allCategoryName?.localized;

  let categoryFiltered: CatalogQuery['newTemplate']['nodes'] = [];

  const allTemplates = data?.newTemplate?.nodes;

  if (allTemplates && selectedCategoryId) {
    categoryFiltered = allTemplates.filter((template) =>
      template.category.some((c) => c._id === selectedCategoryId)
    );
  }

  const highlightedTemplates =
    data?.newTemplate.nodes.filter((node) => node.highlighted === true) ?? [];
  useSaveUtmParamsToLocalStorage();

  useEffect(() => {
    trackPage({
      pageSlug: config?.slug?.localized,
    });
  }, [config?.slug?.localized]);

  return (
    // TODO: Set up blog meta data & navigation bar in Sanity
    <PageWrapper
      locale={pageContext.locale}
      navigationBar={config?.navigationBar as HeaderFragmentWithVariant}
      metadata={config?.metadata}
      announcementId={data?.catalogConfig?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      <Catalog
        title={title}
        description={description}
        coverImage={coverImage}
        categories={categories}
        highlightedTemplates={highlightedTemplates}
        groups={groups}
        categoryFiltered={categoryFiltered}
        selectedCategoryId={selectedCategoryId}
        catalogInstanceSlug={config?.slug?.localized}
        allCategoryName={allCategoryName}
      />
    </PageWrapper>
  );
};

export const Head = () => (
  <>
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: analyticsSnippet,
      }}
    />
    <Script
      async
      src="https://tag.clearbitscripts.com/v1/pk_54aa5d556dad4b9548cc7054eba8b2ca/tags.js"
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </>
);

export const query = graphql`
  query Catalog($id: String!, $locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    catalogConfig: sanityCatalogConfig(_id: { eq: $id }) {
      title {
        localized(locale: $locale)
      }
      description {
        localized(locale: $locale)
      }
      slug {
        localized(locale: $locale)
      }
      metadata {
        ...SEO
      }
      allCategoryName {
        localized(locale: $locale)
      }
      navigationBar {
        ...Header
      }
      coverImage {
        ...ImageUrl
      }
      redirects
    }
    catalogGroup: allSanityCatalogGroup(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...CatalogGroup
      }
    }
    catalogCategory: allSanityCatalogCategory(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...CatalogCategory
      }
    }
    newTemplate: allSanityNewTemplate(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...Template
      }
    }
  }
`;

// ts-prune-ignore-next
export default CatalogOverview;
